import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class SidenavService {
  private mobileMenuOpened = new Subject<boolean>();
  private mobileMenuOpenState = false;

  constructor() {
  }

  public toggleMobileMenu() {
    this.mobileMenuOpenState = !this.mobileMenuOpenState;
    this.mobileMenuOpened.next(this.mobileMenuOpenState);
  }

  public openMobileMenu() {
    this.mobileMenuOpenState = true;
    this.mobileMenuOpened.next(this.mobileMenuOpenState);
  }

  public closeMobileMenu() {
    this.mobileMenuOpenState = false;
    this.mobileMenuOpened.next(this.mobileMenuOpenState);
  }

  public isMobileMenuOpened(): Observable<boolean> {
    return this.mobileMenuOpened.asObservable();
  }

}
