import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import includes from 'lodash/includes';
import remove from 'lodash/remove';
import { environment } from '../../../environments/environment'

declare let _satellite: any;
declare let dataLayer: any;
declare let tagData: any;

@Injectable()
export class AnalyticsService {
  protected initialLoad = false;
  protected currentPageEvent = {
    event: 'Pageview',
    loginStatus: 'out',
    pageArea: 'pub',
    pageItem: 'blinkfitness',
    pageSection: 'blog',
    experience: 'v2',
    pageType: 'Blog Page'
  };

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  public trackPageView(page: string) {
    this.trackGTM({ page });
    this.trackAdm({ page });
  }

  protected trackGTM(data: { page: string }) {
    if (this.initialLoad) {
      remove(dataLayer, (elem: any) => ! includes(elem.event, 'gtm.'));
      dataLayer.push({
        ...this.currentPageEvent,
        pageItem: data.page || 'blinkfitness'
      });
    } else {
      this.initialLoad = true;
    }
  }

  protected trackAdm(data: { page: string }) {
      tagData.global.pageItem = (data && data.page) || 'blinkfitness';
  }

  loadAdobeDTM() {
    const dynamicScript = environment.adobeDTMSnippet;
    const node = this.document.createElement('script');
    node.src = dynamicScript;
    node.type = 'text/javascript';
    node.async = false;
    node.defer = true;
    node.charset = 'utf-8';
    this.document.getElementsByTagName('head')[0].appendChild(node);
  }

}
