import {Injectable, Inject} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {Meta, Title} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';
import {LoggerService} from './log4ts/logger.service';
import isNil from 'lodash/isNil';

@Injectable()
export class SeoService {

  canonicalUrlHtml: HTMLLinkElement;

  constructor(private logger: LoggerService,
              private meta: Meta,
              private titleService: Title,
              @Inject(DOCUMENT) private doc) {

  }


  /**
   * Dynamically sets Canonical Url in the <head>
   * @param customizedCanonicalUrl optional string
   */
  setCanonicalUrl(customizedCanonicalUrl?: string) {
    
    this.removeCanonicalUrl();
    this.canonicalUrlHtml = this.doc.createElement('link');
    this.canonicalUrlHtml.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(this.canonicalUrlHtml);
    // remove query string from current url
    const [currentUrlWithoutQueryString] = this.doc.URL.split('?');
    this.canonicalUrlHtml.setAttribute('href', isNil(customizedCanonicalUrl) ? currentUrlWithoutQueryString : customizedCanonicalUrl);
  }


  /**
   * Removes the added Canonical URL element if it exists
   */
   removeCanonicalUrl(): void{
    if (this.canonicalUrlHtml) {
      this.canonicalUrlHtml.remove();
      }
  }

  generateTags(config?) {
    // default values
    config = {
      title: 'Blink Fitness Blog: Fitness, Nutrition & Rejuvenation',
      description: 'Get the latest fitness trends, nutrition tips & tricks, and rejuvenation articles from Blink Fitness personal trainers to get the most out of your workout routine.',
      image: environment.blinkfitnessSocialUrl,
      url: environment.domain,
      slug: 'BlinkFitness',
      author: 'Blinkfitness.com',
      keywords: 'fitness, gym, blink gym, workout, training, best value, working out, weights, training, strength equipment, cardio equipment, gym near me, cheap gym, gym membership, affordable gym, healthy, fit, affordable gym, clean gym, personal training, boot camp, free trial, new york city',
      ...config
    };


    this.meta.updateTag({name: 'keywords', content: config.keywords});
    this.meta.updateTag({name: 'author', content: config.author});
    this.meta.updateTag({name: 'description', content: config.description});
    this.titleService.setTitle(config.title);

    // http://ogp.me/
    this.meta.updateTag({name: 'twitter:card', content: 'summary'});
    this.meta.updateTag({name: 'twitter:site', content: config.url});
    this.meta.updateTag({name: 'twitter:title', content: config.title});
    this.meta.updateTag({name: 'twitter:description', content: config.description});
    this.meta.updateTag({name: 'twitter:image', content: config.image});

    this.meta.updateTag({property: 'og:type', content: 'website'});
    this.meta.updateTag({property: 'og:site_name', content: config.author});
    this.meta.updateTag({property: 'og:title', content: config.title});
    this.meta.updateTag({property: 'og:description', content: config.description});
    this.meta.updateTag({property: 'og:image', content: config.image});
    this.meta.updateTag({property: 'og:url', content: config.url});
  }


}
