import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {


    const authReq = req.clone({
      headers: req.headers.set('x-api-key', environment.API_CONF.X_API_KEY)
    });

    return next.handle(authReq);
  }
}
