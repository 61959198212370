import { Injectable } from '@angular/core';
import { Logger } from './logger.service';
import {environment} from '../../../../../environments/environment';

export let isDebugMode = environment.isDebugMode;

const noop = (): any => undefined;

@Injectable()
export class ConsoleLoggerService implements Logger {

    get info() {
        if (isDebugMode) {
          // tslint:disable-next-line:no-console
            return console.info.bind(console);
        } else {
            return noop;
        }
    }

    get warn() {
        if (isDebugMode) {
            return console.warn.bind(console);
        } else {
            return noop;
        }
    }

    get error() {
        if (isDebugMode) {
            return console.error.bind(console);
        } else {
            return noop;
        }
    }

    get table() {
        if (isDebugMode) {
            return console.table.bind(console);
        } else {
            return noop;
        }
    }

    invokeConsoleMethod(type: string, args?: any): void {
      // tslint:disable-next-line:ban-types
        const logFn: Function = (console)[type] || console.log || noop;
        logFn.apply(console, [args]);
    }
}
