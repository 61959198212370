import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {environment} from '@environment';
import {Post, Category, Tag, HeaderLinks, Pagination, IHomePageCMS, GetPostsParams} from '@models';

/**
 * Cms Service
 */
@Injectable({
  providedIn: 'root'
})
export class CmsService {
  /**
   * @ignore
   */
  private _headerLinks = new BehaviorSubject<HeaderLinks>(null);
  /**
   * @ignore
   */
  private _posts = new BehaviorSubject<Post[]>([]);
  /**
   * @ignore
   */
  private _pagination = new BehaviorSubject<Pagination>({});
  /**
   * @ignore
   */
  private _homePage = new BehaviorSubject<IHomePageCMS>(null);
  /**
   * @ignore
   */
  private dataStore: { posts: Post[] } = {posts: []};
  /**
   * @ignore
   */
  readonly headerLinks$ = this._headerLinks.asObservable();
  /**
   * @ignore
   */
  readonly posts = this._posts.asObservable();
  /**
   * @ignore
   */
  readonly pagination$ = this._pagination.asObservable();
  /**
   * @ignore
   */
  readonly homePage$ = this._homePage.asObservable();

  /**
   * constructor CmsService
   * @param httpClient
   */
  constructor(private httpClient: HttpClient) {
  }

  loadHeaderLinks(): void {
    this.getDataSetBySlugNameWebCms('header-links')
      .subscribe(data => {
        this._headerLinks.next({...data});
      });
  }

  /**
   * @param params
   */
  loadInitialPosts(params: GetPostsParams): void {
    this.getCategoryBySlug('blog')
      .pipe(
        switchMap(category => this.getPostsWithParamWithResponse({
          categories: String(category[0].id),
          ...params
        }))
      ).subscribe((res: HttpResponse<any>) => {
      this.dataStore.posts = res.body;
      this._posts.next(Object.assign({}, this.dataStore).posts);
      this._pagination.next({
        total: parseInt(res.headers.get('x-wp-total'), 10),
        pages: parseInt(res.headers.get('x-wp-totalpages'), 10)
      } as Pagination);
    }, this.onRequestError('Could not load posts.'));
  }

  /**
   * Load more posts button click
   * @param params GetPostArgs
   */
  loadMorePosts(params: GetPostsParams): void {
    this.getCategoryBySlug('blog')
      .pipe(
        switchMap(category => this.getPostsWithParamWithResponse({
          categories: String(category[0].id),
          ...params
        }))
      ).subscribe((res: HttpResponse<any>) => {
        const currentPosts = this._posts.getValue();
        this.dataStore.posts = [...this.dataStore.posts, ...res.body];
        this._posts.next(this.dataStore.posts);
        this._pagination.next({
          total: parseInt(res.headers.get('x-wp-total'), 10),
          pages: parseInt(res.headers.get('x-wp-totalpages'), 10)
        } as Pagination);
    }, this.onRequestError('Could not load posts.'));
  }

  /**
   * @param params
   */
  loadInitialCategoryPosts(params: GetPostsParams): void {
    this.getPostsWithParam(params).subscribe(data => {
      this.dataStore.posts = data;
      this._posts.next(Object.assign({}, this.dataStore).posts);
    }, this.onRequestError('Could not load posts.'));
  }

  /**
   * @param params
   */
  loadMoreCategoryPosts(params: GetPostsParams): void {
    this.getPostsWithParam(params).subscribe(data => {
      this.dataStore.posts = this.dataStore.posts.concat(data);
      this._posts.next(Object.assign({}, this.dataStore).posts);
    }, this.onRequestError('Could not load posts.'));
  }

  /**
   * Load initial tag posts
   * @param params
   */
  loadInitialTagPosts(params: GetPostsParams): void {
    this.getPostsWithParam(params).subscribe(data => {
      this.dataStore.posts = data;
      this._posts.next(Object.assign({}, this.dataStore).posts);
    }, this.onRequestError('Could not load posts.'));
  }

  /**
   * @param params
   */
  loadMoreTagPosts(params: GetPostsParams): void {
    this.getPostsWithParam(params).subscribe(data => {
      this.dataStore.posts = this.dataStore.posts.concat(data);
      this._posts.next(Object.assign({}, this.dataStore).posts);
    }, this.onRequestError('Could not load posts.'));
  }

  /**
   * Retrieve home page data from CMS
   */
  loadHomePageData(): void {
    this.getPageBySlug('home-page')
      .subscribe({
        next: this.onHomePageDataChage.bind(this),
        error: this.onRequestError('Could not load Home page Data'),
      });
  }

  /**
   * Callback from home-page request
   * @param {IHomePageCMS} data
   */
  onHomePageDataChage(data: IHomePageCMS) {
    this._homePage.next(data);
  }

  /**
   * Callback for request error
   * @param {string} message
   * @returns {Function}
   */
  onRequestError(message: string): any {
    return (error: any) => console.log(message);
  }

  /**
   * @param {GetPostsParams} params
   */
  getPostsWithParam(params: GetPostsParams): Observable<Post[]> {
    const getPostsParams = {
      offset: '0',
      per_page: '10',
      page: '1',
      // context: 'embed',
      orderby: 'date',
      ...params
    };
    const options = {
      params: new HttpParams({fromObject: getPostsParams})
    };
    return this.httpClient.get<Post[]>(`${environment.API_CONF.CMS_API}/posts`, options);
  }

  /**
   * @param params
   */
  getPostsWithParamWithResponse(params: GetPostsParams): Observable<HttpResponse<any>> {
    const getPostsParams = {
      offset: '0',
      per_page: '10',
      page: '1',
      // context: 'embed',
      orderby: 'date',
      ...params
    };
    const options = {
      observe: 'response' as 'body',
      params: new HttpParams({fromObject: getPostsParams})
    };
    return this.httpClient.get<HttpResponse<any>>(`${environment.API_CONF.CMS_API}/posts`, options);
  }

  /**
   * Get All posts from CMS
   * @returns Observable<Post[]>
   */
  getAllPosts(): Observable<Post[]> {
    const options = {params: new HttpParams().set('context', 'embed')};
    return this.httpClient.get<Post[]>(`${environment.API_CONF.CMS_API}/posts`, options);
  }

  /**
   * Get post by Slug
   * @param slug
   * @returns Observable<Post>
   */
  getPostBySlug(slug: string): Observable<Post> {
    return this.httpClient.get<any>(`${environment.API_CONF.CMS_API}/posts/slug/${slug}/`).pipe(map(res => res[0]));
  }

  /**
   * Get post by ID
   * @param id
   * @returns Observable<Post>
   */
  getPostById(id: number): Observable<Post> {
    return this.httpClient.get<any>(`${environment.API_CONF.CMS_API}/posts/${id}/`);
  }

  /**
   * Get Category By Slug
   * @returns Observable<Category[]>
   */
  getCategoryBySlug(category_slug): Observable<Category[]> {
    return this.httpClient.get<any>(`${environment.API_CONF.CMS_API}/categories?slug=${category_slug}`);
  }

  /**
   * Get All Categories
   * @returns Observable<Category[]>
   */
  getAllCategories(): Observable<Category[]> {
    return this.httpClient.get<any>(`${environment.API_CONF.CMS_API}/categories/`);
  }

  /**
   * Get Post Tags
   * @param {number[]} tag_ids
   * @returns Observable<Tag[]>
   */
  getPostTags(tag_ids: number[]): Observable<Tag[]> {
    const tags = tag_ids.join(', ');
    const options = {params: new HttpParams().set('include', tags)};
    return this.httpClient.get<Tag[]>(`${environment.API_CONF.CMS_API}/tags`, options);
  }

  /**
   * Get Tag By Slug
   * @param {string} tag_slug
   * @returns Observable<Tag[]>
   */
  getTagBySlug(tag_slug: string): Observable<Tag[]> {
    return this.httpClient.get<any>(`${environment.API_CONF.CMS_API}/tags?slug=${tag_slug}`);
  }

  /**
   * Get Data Set By Slug From Website CMS
   * @param {string} slug
   * @returns Observable<any>
   */
  getDataSetBySlugNameWebCms(slug: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.API_CONF.WEB_CMS_API}/data-sets/slug/${slug}`).pipe(map(data => data[0]));
  }

  /**
   * Get Data Set By Slug From Website CMS
   * @param {string} slug
   * @returns Observable<any>
   */
  getPageBySlug<T>(slug: string): Observable<T> {
    return this.httpClient.get<any>(`${environment.API_CONF.CMS_API}/pages/slug/${slug}`).pipe(map(data => data[0]));
  }

  /**
   * Get Page By Slug From Website CMS
   * @param {string} slug
   * @returns Observable<any>
   */
  getPageBySlugNameWebCms<T>(slug: string): Observable<T> {
    return this.httpClient.get<T>(`${environment.API_CONF.WEB_CMS_API}/pages/slug/${slug}`).pipe(map(data => data[0]));
  }
}
