import {Injectable} from '@angular/core';

import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map, switchMap} from 'rxjs/operators';

const APP_TITLE = 'Blink Fitness';
const SEPARATOR = ' | ';
const PATH_EXCEPTIONS = ['tag']

@Injectable()
export class TitleService {

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title) {

  }

  // tslint:disable-next-line:variable-name
  public static ucFirst(string) {
    if (!string) {
      return string;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  init(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      switchMap(route => route.data),
      map((data) => {
        if (data.title) {
          return data.title;
        } else {
          return this.router.url.split('/').reduce((acc, frag) => {
            if (acc && frag) {
              acc += SEPARATOR;
            }
            return this.router.url.split('/').reduce((acc, frag) => {
              if (acc && frag) {
                acc += SEPARATOR;
              }
              return acc + TitleService.ucFirst(frag.replace(/-/g, ' '));
            });
          });
        }
      })
    ).subscribe((pathString) => {
      // Checks if it's not in the path exceptions to change the title
      if (!PATH_EXCEPTIONS.includes(this.router.url.split('/')[1])) {
        this.titleService.setTitle(`${APP_TITLE} ${pathString}`);
      }
    });
  }

  setPageTitle(title: string): void {
    this.titleService.setTitle(`${APP_TITLE} ${title}`);
  }

}
