<div>
  <lib-blink-header
    currentPage="blog"
    [isScrolling]="false"
    [activeNav]="sidenavOpened$|async"
    (sidebarToggleClick)="sidebarToggle()"
    (freeTrialClicked)="onFreeTrailClick()"
    (joinNowClicked)="onJoinNowClick()"
  >
  </lib-blink-header>
  <!-- Sidebar Mobile -->
  <lib-blink-sidebar
      wpLoopElements
      [isOpen]="sidenavOpened$|async"
      (sidebarToggleClick)="sidebarToggle()"
      (freeTrialClicked)="onFreeTrailClick()"
      (joinNowClicked)="onJoinNowClick()"
  >
  </lib-blink-sidebar>
  <div class="header-ghost-space"></div>
  <div class="page-container">
    <router-outlet></router-outlet>
  </div>
  <div fxHide fxShow.gt-sm='true' fxLayout='column' class='bg-white'>
    <lib-blink-footer></lib-blink-footer>
  </div>
</div>
