import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './core/layout/templates/main/main.component';


const routes: Routes = [
  {
    path: 'student-summer-offer',
    redirectTo: '/student-back-to-school-sale',
    pathMatch: 'full',
  },
  {
    path: '',
    component: MainComponent,
    loadChildren: () => import('./public-pages/public-pages.module').then(mod => mod.PublicPagesModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
