import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';


@Injectable()
export class PreloadService {
   /**
   * PreloadService constructor
   * @param {Document} doc
   */
  constructor(@Inject(DOCUMENT) private doc: Document) {
  }

  /**
   * Preload the largest image of the page to decrease LCP
   * @param imageUrl string
   * @return void | any type
   */
   preloadLargeContentfulImage(imageUrl: string): void {
    // ensure only one <link> of the same url exists
    const existingPreload = this.doc.head.querySelector(`[href="${imageUrl}"][rel="preload"][as="image"]`);
    if (existingPreload) {
      existingPreload.remove();
    }
    const preloadedImage = this.doc.createElement('link');
    preloadedImage.setAttribute('rel', 'preload');
    preloadedImage.setAttribute('as', 'image')
    preloadedImage.setAttribute('href', imageUrl);
    this.doc.head.appendChild(preloadedImage);
  }

}
