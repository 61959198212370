import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs';
import {SidenavService} from '../../../services/other/sidenav.service';
import {environment} from '@environment';


@Component({
  selector: 'blb-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit {
  public sidenavOpened$: Observable<boolean>;
  public date: Date = new Date();
  public blinkWebDomain = environment.blinkWebDomain;
  public yextLocationsDomain = environment.yextLocationsDomain;

  constructor(
    private sidenav: SidenavService,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.sidenavOpened$ = this.sidenav.isMobileMenuOpened();
    this.cdr.detectChanges();
  }

  /**
   * Simple handler for click on header's sidebar button
   */
  sidebarToggle(): void {
    this.sidenav.toggleMobileMenu();
  }

  /**
   * Simple handler for click on header's free trial button
   */
  onFreeTrailClick(): void {
    window.location.href = `${this.blinkWebDomain}/?modal=free-trial` ;
  }

  /**
   * Simple handler for click on header's free join now button
   */
  onJoinNowClick(): void {
    window.location.href = `${this.yextLocationsDomain}/search` ;
  }
}
