import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class PopupService {

  public _trialForm: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public trialForm: Observable<boolean> = this._trialForm.asObservable();

  toggleTrialForm(): void {
    const val = this._trialForm.getValue();
    this._trialForm.next(!val);
  }
}
