import { Injectable, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';

@Injectable()
export class AnchorService {
    subs: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        @Inject(DOCUMENT) public document: Document
    ) {}

    init() {
        this.route.fragment.subscribe(this.onRouteFragment.bind(this));
    }

    onRouteFragment(fragment: string) {
        if (fragment) {
            const element = document.getElementById(fragment);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }
}
