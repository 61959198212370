import { Component } from '@angular/core';
import { TitleService } from './core/services/other/title.service';
import { CmsService } from './core/services/cms.service';
import { AnalyticsService } from './core/services/analytics.service';
import { NavigationEnd, Router } from '@angular/router';
import { SeoService } from '@core/services/other/seo.service';

@Component({
  selector: 'blb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Blink-Blog';

  constructor(
    private titleService: TitleService,
    private cmsService: CmsService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private seoService: SeoService) {
    this.cmsService.loadHeaderLinks();
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        if (ev.url === '/') {
          this.seoService.setCanonicalUrl("");
        } else {
          this.seoService.setCanonicalUrl();
        }
        this.analyticsService.trackPageView(ev.url.replace(/^\/+/g, ''));
      }
    });
    this.analyticsService.loadAdobeDTM();
  }
}
