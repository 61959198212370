import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CmsService } from '@core/services/cms.service';
import { GetPostsParams, IPaginationByTag, IPosts, Pagination, Post, TagsBySlug } from '@models';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  private _tags = new BehaviorSubject<TagsBySlug>(null);
  private _posts = new BehaviorSubject<Post[]>(null);
  private _postsPagination = new BehaviorSubject<Pagination>({});
  private _postsByTag = new BehaviorSubject<IPosts>(null);
  private _paginationByTag = new BehaviorSubject<IPaginationByTag>({});
  private _tagSelected = new BehaviorSubject<string>(null);
  readonly tags$ = this._tags.asObservable();
  readonly posts$ = this._posts.asObservable();
  readonly pagination$ = this._postsPagination.asObservable();
  readonly postsByTag$ = this._postsByTag.asObservable();
  readonly paginationByTag$ = this._paginationByTag.asObservable();
  readonly tagSelected$ = this._tagSelected.asObservable();
  readonly perPage = 6;
  tags = 'fitness, rejuvenation, nutrition';

  /**
   * @constructor
   * @param {CmsService} cmsService
   */
  constructor(
    private cmsService: CmsService,
  ) {}

  /**
   * Sets the currently selected tag
   * @param {string} tag
   */
  setTagSelected(tag: string) {
    this._tagSelected.next(tag);
  }

  /**
   * Load tags by a list of tag slugs
   * @param {string} slugs
   */
  async loadTags() {
    const data = await this.cmsService.getTagBySlug(this.tags).toPromise();
    const value = {
      ...(this._tags.getValue() || {}),
      ...data.reduce((acc, curr) => ({ ...acc, [curr.slug]: curr }), {}),
    };

    this._tags.next(value);
  }

  /**
   * Load more posts button click
   * @param params GetPostArgs
   */
  async loadPosts(params: GetPostsParams = {}): Promise<void> {
    const { page = 0, offset = 0 } = this._postsPagination.getValue() || {};
    const res = await this.cmsService.getCategoryBySlug('blog')
      .pipe(
        switchMap(category => this.cmsService.getPostsWithParamWithResponse({
          page: String(page + 1),
          categories: String(category[0].id),
          per_page: String(this.perPage),
          offset: String(offset),
          ...params,
        }))
      ).toPromise();

    const posts = this._posts.getValue() || [];

    this._posts.next([...posts, ...res.body]);

    this._postsPagination.next({
      page: page + 1,
      offset: offset + this.perPage,
      total: parseInt(res.headers.get('x-wp-total'), 10),
      pages: parseInt(res.headers.get('x-wp-totalpages'), 10)
    } as Pagination);
  }

  /**
   * Load Posts by multiple tags
   * @param {GetPostsParams} params
   */
  async loadPostsByTags(params: GetPostsParams = {}): Promise<void> {
    // const paginationByTag = this._paginationByTag.getValue() || {};
    const tags = params.tags.split(',').map((tag) => {
      return this.loadPostsByTag({
        ...params,
        tags: tag,
      });
    });

    await Promise.all(tags);
  }

  /**
   * Load Tag Posts
   * @param {GetPostsParams} params
   */
  async loadPostsByTag(params: GetPostsParams = {}): Promise<void> {
    if (params.tags.includes(',')) {
      return this.loadPostsByTags(params);
    }

    const paginationByTag = this._paginationByTag.getValue() || {};
    const { page = 0, offset = 0 } = paginationByTag[params.tags] || {};
    const res = await this.cmsService
      .getPostsWithParamWithResponse({
        page: String(page + 1),
        per_page: String(this.perPage),
        offset: String(offset),
        ...params,
      })
      .toPromise();

    const postsByTag = this._postsByTag.getValue() || {};
    this._postsByTag.next({
      ...postsByTag,
      [params.tags]: [
        ...(postsByTag[params.tags] || []),
        ...(res.body || []),
      ],
    });

    this._paginationByTag.next({
      ...paginationByTag,
      [params.tags]: {
        page: page + 1,
        offset: offset + this.perPage,
        total: parseInt(res.headers.get('x-wp-total'), 10),
        pages: parseInt(res.headers.get('x-wp-totalpages'), 10)
      } as Pagination,
    });
  }
}
