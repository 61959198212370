import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutModule} from './layout/layout.module';
import {P404Component} from '../public-pages/other/404.component';
import {P500Component} from '../public-pages/other/500.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './services/core/auth-interceptor';
import {CmsService} from './services/cms.service';
import {LocalStorageService} from './services/other/local-storage.service';
import {LoggerService} from './services/other/log4ts/logger.service';
import {ConsoleLoggerService} from './services/other/log4ts/console-logger.service';
import {SidenavService} from './services/other/sidenav.service';
import {FlexModule} from '@angular/flex-layout';
import {PopupService} from './services/popup.service';
import {AnalyticsService} from './services/analytics.service';
import { SafeHtmlPipe } from './pipes/safe-html-pipe';
import { AnchorService } from './services/anchor.service';
import { BlogService } from './services/blog.service';
import { PreloadService } from './services/preload.service';

@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    HttpClientModule,
    FlexModule
  ],
  declarations: [
    P404Component,
    P500Component,
    SafeHtmlPipe
  ],
  exports: [
    P404Component,
    P500Component,
    SafeHtmlPipe
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LoggerService, useClass: ConsoleLoggerService },
    CmsService,
    PopupService,
    AnalyticsService,
    SidenavService,
    LocalStorageService,
    AnchorService,
    BlogService,
    PreloadService
  ]
})
export class CoreModule {
}
